import React, { memo, useState, useEffect, useRef } from 'react';
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import getSubContractorEventLogs from "components/ContractInFocus/Logs/Components/SubcontractorEventLogs";
import getTechnicalEventLogs from "components/ContractInFocus/Logs/Components/TechnicalEventLogs";

import { PureAudit } from "components/ContractInFocus/Audit/Audit";

import "./TaskDash.scss";

interface TaskDashProps {
    contract?: SiteContract;
    portfolio?: HydratedPortfolio;
}

export default function TaskDashView({
    contract, portfolio
}: TaskDashProps) {
    const SubContractorEventLogs = memo(getSubContractorEventLogs(contract, portfolio));
    const TechnicalEventLogs = memo(getTechnicalEventLogs(contract, portfolio));
    const [expand, setExpand] = useState<string|null>(null);
    const firstQuadrantRef = useRef<any>(null);
    const secondQuadrantRef = useRef<any>(null);
    const thirdQuadrantRef = useRef<any>(null);
    const fourthQuadrantRef = useRef<any>(null);
    useEffect(() => {

    }, [expand])
    return <div className='taskDash'>
        <div id='urgentListsContainer'>
            <div ref={firstQuadrantRef} className="quadrant" id='firstQuadrant' 
                onMouseEnter={() => {
                    firstQuadrantRef.current.style.zIndex = 1;
                    secondQuadrantRef.current.style.zIndex = 0;
                    thirdQuadrantRef.current.style.zIndex = 0;
                    fourthQuadrantRef.current.style.zIndex = 0;
                }}
            >
                <SubContractorEventLogs/>
            </div>
            <div ref={secondQuadrantRef} className="quadrant" id='secondQuadrant'
                onMouseEnter={() => {
                    firstQuadrantRef.current.style.zIndex = 0;
                    secondQuadrantRef.current.style.zIndex = 1;
                    thirdQuadrantRef.current.style.zIndex = 0;
                    fourthQuadrantRef.current.style.zIndex = 0;
                }}
            >
                <TechnicalEventLogs/>
            </div>
            <div ref={thirdQuadrantRef} className="quadrant" id='thirdQuadrant'
                onMouseEnter={() => {
                    firstQuadrantRef.current.style.zIndex = 0;
                    secondQuadrantRef.current.style.zIndex = 0;
                    thirdQuadrantRef.current.style.zIndex = 1;
                    fourthQuadrantRef.current.style.zIndex = 0;
                }}
            >
               <PureAudit contract={contract} portfolio={portfolio}/>
            </div>
            <div ref={fourthQuadrantRef} className="quadrant" id='fourthQuadrant'
                onMouseEnter={() => {
                    firstQuadrantRef.current.style.zIndex = 0;
                    secondQuadrantRef.current.style.zIndex = 0;
                    thirdQuadrantRef.current.style.zIndex = 0;
                    fourthQuadrantRef.current.style.zIndex = 1;
                }}
            >
               <PureAudit contract={contract} portfolio={portfolio}/>
            </div>
        </div>
    </div>
}